import { ToastProvider } from '@/components/common/Toast'
import initializeFirebase from '@/lib/firebase'
import '@/styles/globals.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Inter } from 'next/font/google'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

// progress bar
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import '../styles/nprogess.css'

// initialize firebase app
initializeFirebase()

// configure progress bar
NProgress.configure({
  showSpinner: true,
  trickle: true,
  trickleRate: 0.02,
  trickleSpeed: 500,
  easing: 'ease',
  speed: 500,
})

// font
const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
})

export default function App({ Component, pageProps }) {
  // Create a client
  const queryClient = new QueryClient()

  const router = useRouter()

  useEffect(() => {
    const handleRouteChangeStart = () => {
      NProgress.start()
    }

    const handleRouteChangeComplete = () => {
      NProgress.done()
    }

    const handleRouteChangeError = () => {
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [router])

  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <Head>
          <link rel="icon" href="/favicon/favicon.ico" sizes="16x16" />
          <link rel="icon" href="/favicon/favicon-32x32.png" sizes="32x32" />
          <link rel="apple-touch-icon" href="/favicon/apple-touch-icon.png" sizes="180x180" />
          <link
            rel="icon"
            href="/favicon/android-chrome-192x192.png"
            sizes="192x192"
            type="image/png"
          />
          <link
            rel="icon"
            href="/favicon/android-chrome-512x512.png"
            sizes="512x512"
            type="image/png"
          />
          <link rel="manifest" href="/favicon/site.webmanifest" />
          <meta name="theme-color" content="#ffffff" />
        </Head>
        <main className={`${inter.variable} font-sans`}>
          <Component {...pageProps} />
        </main>
      </ToastProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}
