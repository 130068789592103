import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'
import {
  Fragment,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
// custom imports
import { debounce } from '../../lib/utils'

// create context
export const ToastContext = createContext()

// create ToastProvider
export function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showToast = useCallback(
    debounce(toast => {
      const id = toast.id || Date.now()
      setToasts(prevToasts => [{ ...toast, id }, ...prevToasts])
    }, 300),
    [],
  )

  const removeToast = useCallback(id => {
    setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ toasts, showToast, removeToast }}>
      {children}
      <ToastContainer />
    </ToastContext.Provider>
  )
}

// context hook
export function useToast() {
  return useContext(ToastContext)
}

// toast container
const ToastContainer = memo(
  function ToastContainer() {
    const { toasts } = useToast()

    return (
      <div className="pointer-events-none fixed inset-0 top-12 z-50 flex items-start px-4 py-6 sm:items-start sm:p-6">
        <div className="z-10 flex w-full flex-col items-center space-y-4 sm:items-end">
          {toasts.map(toast => (
            <Toast key={toast.id} {...toast} />
          ))}
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.toasts &&
      prevProps.toasts.length === nextProps.toasts.length &&
      prevProps.toasts.every((toast, index) => toast.id === nextProps.toasts[index].id)
    )
  },
)

function getIconAndColor(type) {
  let color
  let Icon
  let focusColor
  switch (type) {
    case 'error':
      color = 'text-red-500'
      focusColor = 'ring-red-500'
      Icon = ExclamationTriangleIcon
      break
    case 'warning':
      color = 'text-orange-500'
      focusColor = 'ring-orange-500'
      Icon = ExclamationCircleIcon
      break
    case 'info':
      color = 'text-indigo-500'
      focusColor = 'ring-indigo-500'
      Icon = InformationCircleIcon
      break
    default:
      Icon = CheckCircleIcon
      focusColor = 'ring-green-500'
      color = 'text-green-500'
  }
  return { Icon, color, focusColor }
}

// Toast
export const Toast = memo(
  function Toast(props) {
    const { id, message, description, type = 'success', duration = 4000 } = props
    const [show, setShow] = useState(true)
    const timerRef = useRef(null)

    const { Icon, color, focusColor } = getIconAndColor(type)

    const { removeToast } = useToast()

    useEffect(() => {
      timerRef.current = setTimeout(() => {
        setShow(false)
      }, duration)
      return () => clearTimeout(timerRef.current)
    }, [duration])

    useEffect(() => {
      if (!show) {
        removeToast(id)
      }
    }, [show, removeToast, id])

    const handleMouseEnter = () => {
      clearTimeout(timerRef.current)
    }

    const handleMouseLeave = () => {
      timerRef.current = setTimeout(() => {
        setShow(false)
      }, duration)
    }

    return (
      <Transition
        show={show}
        as={Fragment}
        enter="transform transition-all ease-out duration-300"
        enterFrom="translate-x-full opacity-0"
        enterTo="translate-x-0 opacity-100"
        leave="transition ease-in duration-100"
        leaveFrom="translate-x-0 opacity-100"
        leaveTo="translate-x-full opacity-0"
      >
        <div
          className="pointer-events-auto z-0 w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <Icon className={`h-6 w-6 ${color}`} aria-hidden="true" />
              </div>
              <div className="ml-3 w-0 flex-1">
                <p className={`text-sm font-medium ${color}`}>{message}</p>
                {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className={`inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 ${focusColor} focus:ring-offset-2`}
                  onClick={() => {
                    setShow(false)
                    removeToast(id)
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.message === nextProps.message &&
      prevProps.description === nextProps.description &&
      prevProps.type === nextProps.type &&
      prevProps.duration === nextProps.duration &&
      prevProps.show === nextProps.show
    )
  },
)
